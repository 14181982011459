<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <h2>Category</h2>
    </div>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
      <v-text-field
          v-model="name"
          :rules="requiredRules"
          label="Name"
          required
      ></v-text-field>

      <div>
        <label class="v-label theme--light">Color</label>
        <v-text-field v-model="color" hide-details class="ma-0 pa-0" solo>
          <template v-slot:append>
            <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <div :style="swatchStyle" v-on="on" />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker v-model="color" flat />
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
        </v-text-field>
      </div>
      <div>
        <v-file-input
            label="Image"
            show-size
            truncate-length="30"
            @change="onFileChange"
        ></v-file-input>
        <img
            v-if="imagePreviewURL"
            :src="imagePreviewURL"
            alt=""
            style="max-width: 100%;width: 250px; object-fit: cover"
        />
      </div>
      <v-switch
          v-model="active"
          :label="`${active ? 'Active': 'Disable'}`"
      ></v-switch>
      <v-btn
          :disabled="!valid"
          :loading="isLoading"
          color="success"
          class="mr-4"
          @click="submit"
      >
        Submit
      </v-btn>
    </v-form>
  </div>
</template>


<script>
  export default {
    name: 'Category',
    components: {

    },
    data() {
      return {
        isLoading: false,
        valid: true,
        name: '',
        active: false,
        image: '',
        imagePreviewURL: '',
        color: '#1976D2FF',
        mask: '!#XXXXXXXX',
        menu: false,
        requiredRules: [
          v => !!v || 'Field is required',
        ],
      }
    },
    mounted() {
      this.getCategory();
    },
    methods: {
      submit() {
        if (this.$refs.form.validate()) {
          this.isLoading = true;
          let url = '/categories'
          let id = this.$route.params.id && this.$route.params.id !== 'create' ? this.$route.params.id : null;
          if (id) {
            url += '/' + id;
          }
          let data = {
            name: this.name,
            color: this.color,
            active: this.active,
          }
          if (this.image) {
            data.image = this.image;
          }
          this.$axios[id ? 'put' : 'post'](url, data).then((res) => {
            this.$root.notify({color: 'success', text: 'Success created'})

            this.$router.push("/categories")
          }).catch(err => {
            this.isLoading = false;
            this.$root.notify({color: 'error', text: err.error ? err.error : "Unknown Error!"})
          });
        } else {
          this.$root.notify({color: 'error', text: 'Fill all fields'})
        }
      },
      onFileChange(payload) {
        const file = payload;
        this.image = file;
        if (file) {
          this.imagePreviewURL = URL.createObjectURL(file);
          URL.revokeObjectURL(file);
          this.uploadFile(file)
        } else {
          this.imagePreviewURL =  null
        }
      },
      uploadFile(f) {
        let formData = new FormData();
        formData.append("files", f);

        return this.$axios.post('/' + this.$store.state.filesUrl, formData).then(res => {
          this.image = res.data[0]._id;
        }).catch(err => {
          this.$root.notify({color: 'error', text: `${f.name} upload failed`})
        })
      },
      getCategory() {
        let id = this.$route.params.id;
        if (id && id !== 'create') {
          let url = '/categories/' + id;
          this.$axios.get(url).then(res => {
            this.name = res.data.name;
            this.color = res.data.color;
            this.active = res.data.active;
            if (res.data.image) {
              this.imagePreviewURL = this.$store.state.appUrl + res.data.image.url;
            }
          }).catch(e => {
            console.log(e)
          });

        }
      }
    },
    watch: {

    },
    computed: {
      swatchStyle() {
        const { color, menu } = this
        return {
          backgroundColor: color,
          cursor: 'pointer',
          height: '30px',
          width: '30px',
          borderRadius: menu ? '50%' : '4px',
          transition: 'border-radius 200ms ease-in-out'
        }
      }
    }
  }

</script>

